<template>
  <div class="turntable-rule">
    <div>一、活动时间：</div>
    <div>2022年10月20日至2022年11月19日</div>
    <div>二、活动规则：</div>
    <div>
      同一用户每天都可获得1次抽奖机会（即“日常抽奖”），用户通过完成页面中披露的任务获得额外的抽奖机会（即“任务抽奖”），每次抽奖都将消耗1次抽奖机会，单次抽奖最多只可抽中1个奖品（包含谢谢参与）。
    </div>
    <div>三、特别说明：</div>
    <div>1.活动奖品分为不同级别，奖品将不定期进行更新。</div>
    <div>2.如某一奖品被用户抽完，主办方可以但无义务补充其他奖品。</div>
    <div>
      3.开奖时间和方式：用户点击转动转盘按钮后实时开奖，对应奖品将自动发放至注册账户中。
    </div>
    <div>四、奖品说明：</div>
    <div>1.中奖后，奖品可在抽奖页【我的奖品】内查看。</div>
    <div>
      2.现金红包，奖品中的现金红包，在中奖后将自动发放至APP【我的钱包】中。
    </div>
    <div>
      3.话费充值，奖品中的话费充值类，在中奖后将与5个工作日内充值到注册手机号码中，不可折现，转增等。
    </div>
    <div>五、活动其他说明：</div>
    <div>1.活动仅限融树客注册用户参与和使用</div>
    <div>
      2.凡以不正当手段（包含但不限于扰乱系统、实施网络攻击等）参与活动的用户，或用户涉嫌违法违规行为的（例如洗钱、作弊），我们有权终止其参与活动，取消其获奖资格，并有权回收用户已经获得的奖品。
    </div>
    <div>
      3.活动期间，如活动受政府机关指令或其他三方限制（例如应用市场等）需要停止，或遭遇自然灾害、网络攻击或系统故障等不可抗因素或其他任何原因导致活动暂停举办，主办方无需承担任何赔偿责任或进行任何补偿。
    </div>
    <div>
      4.主办方可根据活动举行的实际情况，在法律允许的范围内，对本活动规则随时进行变动或调整，相关变动或调整将公布在活动规则页面上并立即生效，无需事先征得任何用户同意。
    </div>
    <div>5.此活动与Apple.Inc无关。</div>
    <div>
      <p>【双十一幸运大转盘奖品清单】</p>
      <p>「谢谢参与」</p>
      <p>「再来一次」</p>
      <p>「5元现金红包」</p>
      <p>「30天免息券」</p>
      <p>「5万元额度提升券」</p>
      <p>「50元手机话费」</p>
    </div>
  </div>
</template>

<script>
export default {
  name: "TurntableRule",
};
</script>

<style lang="scss" scoped>
.turntable-rule {
  padding: 0.3rem;
  font-size: 0.28rem;
  line-height: 0.5rem;
  text-align: justify;
  color: rgba(35, 45, 65, 0.99);

  > div {
    margin-bottom: 0.3rem;
  }
}
</style>
